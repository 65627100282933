var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("validation-provider", {
    attrs: { vid: _vm.vid, name: _vm.name, rules: _vm.rules },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ dirty, validated, valid, errors }) {
          return [
            _c(
              "b-form-group",
              {
                class: {
                  asterisk:
                    Object.keys(_vm.rules).includes("required") ||
                    (Object.keys(_vm.rules).includes("required_if") && !valid),
                },
                attrs: {
                  id: `input-group-${_vm.id}`,
                  label: _vm.label,
                  "label-for": `input-${_vm.id}`,
                },
              },
              [
                _c(
                  "b-input-group",
                  { staticClass: "mb-3" },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: `input-${_vm.id}`,
                        type: "text",
                        state: _vm.getValidationState({
                          dirty,
                          validated,
                          valid,
                          errors,
                        }),
                        "aria-describedby": `input-${_vm.id}-live-feedback`,
                        placeholder: "GG/MM/AAAA",
                        autocomplete: "off",
                        title: _vm.inputVal.split("-").reverse().join("/"),
                      },
                      on: { input: _vm.onTextInput },
                      model: {
                        value: _vm.inputVal,
                        callback: function ($$v) {
                          _vm.inputVal = $$v
                        },
                        expression: "inputVal",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "b-input-group-append",
                      [
                        _c("b-form-datepicker", {
                          attrs: {
                            id: `datepicker-input-${_vm.id}`,
                            name: `input-${_vm.id}`,
                            type: _vm.type,
                            "show-decade-nav": "",
                            locale: "it",
                            "date-format-options": {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            },
                            "button-only": "",
                            "button-variant": "lisaweb",
                            right: "",
                          },
                          on: { context: _vm.onContext, input: _vm.onInput },
                          model: {
                            value: _vm.inputVal,
                            callback: function ($$v) {
                              _vm.inputVal = $$v
                            },
                            expression: "inputVal",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-invalid-feedback",
                  { attrs: { id: `input-${_vm.id}-live-feedback` } },
                  [_vm._v(_vm._s(errors[0]))]
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }