var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "quickSearchModal",
        size: "lg",
        scrollable: "",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [
                  _c("b-icon", { attrs: { icon: "calendar2-event" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Cerca Anagrafica")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: "Chiudi",
                    "data-dismiss": "modal",
                  },
                  on: {
                    click: function ($event) {
                      return cancel()
                    },
                  },
                },
                [_vm._v("\n      ×\n    ")]
              ),
            ]
          },
        },
        {
          key: "modal-footer",
          fn: function ({ cancel }) {
            return [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-btn",
                    {
                      attrs: { size: "sm", variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          cancel()
                          _vm.clear()
                        },
                      },
                    },
                    [_vm._v("Annulla")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-btn",
                    {
                      attrs: { size: "sm", variant: "outline-lisaweb" },
                      on: {
                        click: function ($event) {
                          cancel()
                          _vm.setValue()
                        },
                      },
                    },
                    [_vm._v("Seleziona")]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "b-card",
        [
          _c(
            "b-card-text",
            [
              _c("base-select", {
                attrs: {
                  vid: "quick_search",
                  name: "quick_search",
                  label: "Codice Fiscale",
                  options: _vm.ajaxOptions,
                  isLoading: _vm.isLoadingAjax,
                },
                on: {
                  "search-change": _vm.getAjaxOptions,
                  select: _vm.onSelect,
                  remove: _vm.onRemove,
                },
                model: {
                  value: _vm.inputVal,
                  callback: function ($$v) {
                    _vm.inputVal = $$v
                  },
                  expression: "inputVal",
                },
              }),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.surname) +
                  " " +
                  _vm._s(_vm.name) +
                  "\n    "
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }