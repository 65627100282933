var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(
      _vm.inputs[_vm.groupingField] || _vm.inputs,
      function (input, index) {
        return _c("b-row", { key: index }, [
          _vm.getInputType(_vm.beForm[input]) === "text"
            ? _c(
                "div",
                { staticClass: "form-group col-md-6" },
                [
                  _c("base-input", {
                    attrs: {
                      name: _vm.beForm[input].label,
                      vid: input,
                      label: _vm.beForm[input].label,
                      rules: _vm.getRules(input),
                    },
                    model: {
                      value: _vm.inputVal[input],
                      callback: function ($$v) {
                        _vm.$set(_vm.inputVal, input, $$v)
                      },
                      expression: "inputVal[input]",
                    },
                  }),
                ],
                1
              )
            : _vm.getInputType(_vm.beForm[input]) === "textarea"
            ? _c(
                "div",
                { staticClass: "form-group col-md-6" },
                [
                  _c("base-textarea", {
                    attrs: {
                      name: _vm.beForm[input].label,
                      vid: input,
                      label: _vm.beForm[input].label,
                      rules: _vm.getRules(input),
                      rows: "4",
                      maxrows: "4",
                    },
                    model: {
                      value: _vm.inputVal[input],
                      callback: function ($$v) {
                        _vm.$set(_vm.inputVal, input, $$v)
                      },
                      expression: "inputVal[input]",
                    },
                  }),
                ],
                1
              )
            : _vm.getInputType(_vm.beForm[input]) === "select"
            ? _c(
                "div",
                { staticClass: "form-group col-md-6" },
                [
                  _c("base-select", {
                    attrs: {
                      name: _vm.beForm[input].label,
                      vid: input,
                      label: _vm.beForm[input].label,
                      options: _vm.beForm[input].options,
                      rules: _vm.getRules(input),
                      taggable: false,
                      multiple: false,
                      closeOnSelect: true,
                    },
                    model: {
                      value: _vm.inputVal[input],
                      callback: function ($$v) {
                        _vm.$set(_vm.inputVal, input, $$v)
                      },
                      expression: "inputVal[input]",
                    },
                  }),
                ],
                1
              )
            : _vm.getInputType(_vm.beForm[input]) === "datepicker"
            ? _c(
                "div",
                { staticClass: "form-group col-md-6" },
                [
                  _c("base-datepicker", {
                    attrs: {
                      name: _vm.beForm[input].label,
                      vid: input,
                      label: _vm.beForm[input].label,
                      rules: _vm.getRules(input),
                    },
                    model: {
                      value: _vm.inputVal[input],
                      callback: function ($$v) {
                        _vm.$set(_vm.inputVal, input, $$v)
                      },
                      expression: "inputVal[input]",
                    },
                  }),
                ],
                1
              )
            : _vm.getInputType(_vm.beForm[input]) === "timepicker"
            ? _c(
                "div",
                { staticClass: "form-group col-md-6" },
                [
                  _c("base-timepicker", {
                    attrs: {
                      name: _vm.beForm[input].label,
                      vid: input,
                      label: _vm.beForm[input].label,
                      rules: _vm.getRules(input),
                    },
                    model: {
                      value: _vm.inputVal[input],
                      callback: function ($$v) {
                        _vm.$set(_vm.inputVal, input, $$v)
                      },
                      expression: "inputVal[input]",
                    },
                  }),
                ],
                1
              )
            : _vm.getInputType(_vm.beForm[input]) === "checkbox"
            ? _c(
                "div",
                { staticClass: "form-group col-md-6" },
                [
                  _c("base-select", {
                    attrs: {
                      name: _vm.beForm[input].label,
                      vid: input,
                      label: _vm.beForm[input].label,
                      options: _vm.beForm[input].options,
                      rules: _vm.getRules(input),
                      taggable: false,
                      multiple: false,
                      closeOnSelect: true,
                    },
                    model: {
                      value: _vm.inputVal[input],
                      callback: function ($$v) {
                        _vm.$set(_vm.inputVal, input, $$v)
                      },
                      expression: "inputVal[input]",
                    },
                  }),
                ],
                1
              )
            : _vm.getInputType(_vm.beForm[input]) === "radio"
            ? _c(
                "div",
                { staticClass: "form-group col-md-6" },
                [
                  _c("base-radio", {
                    attrs: {
                      name: _vm.beForm[input].label,
                      vid: input,
                      label: _vm.beForm[input].label,
                      options: _vm.beForm[input].options,
                      rules: _vm.getRules(input),
                      stacked: "",
                    },
                    model: {
                      value: _vm.inputVal[input],
                      callback: function ($$v) {
                        _vm.$set(_vm.inputVal, input, $$v)
                      },
                      expression: "inputVal[input]",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      }
    ),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }