var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("validation-provider", {
    attrs: {
      vid: _vm.vid,
      name: _vm.name,
      rules: _vm.isAssistence ? _vm.rules : {},
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ dirty, validated, valid, errors }) {
          return [
            _vm.isAssistence
              ? _c(
                  "b-form-group",
                  {
                    class: {
                      asterisk:
                        _vm.isAssistence &&
                        Object.keys(_vm.rules).includes("required"),
                    },
                    attrs: {
                      id: `input-group-${_vm.id}`,
                      label: _vm.label,
                      "label-for": `input-${_vm.id}`,
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c(
                          "label",
                          {
                            attrs: {
                              state: _vm.getValidationState({
                                dirty,
                                validated,
                                valid,
                                errors,
                              }),
                            },
                          },
                          [_vm._v("\n        " + _vm._s(_vm.content))]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-input-group-append",
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { type: "button", variant: "lisaweb" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openModal()
                                  },
                                },
                              },
                              [_vm._v("Modifica")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-modal",
                          {
                            attrs: {
                              id: `modal-${_vm.id}`,
                              title: "Assistenza",
                              size: "sl",
                              "ok-only": "",
                              "ok-variant": "lisaweb",
                              scrollable: "",
                            },
                            on: {
                              hide: function ($event) {
                                return _vm.hideModal()
                              },
                            },
                          },
                          [
                            _c("pre", [_vm._v(_vm._s(_vm.inputVal))]),
                            _vm._v(" "),
                            _c("pre", [_vm._v(" " + _vm._s(_vm.content))]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "\n          Deve mostrare un campo di ricerca specifico alla relazione, e\n          aggiornare l'id della relazione\n        "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: `input-group-${_vm.id}`,
                      label: _vm.label,
                      "label-for": `input-${_vm.id}`,
                    },
                  },
                  [
                    _vm.controlType === "text"
                      ? _c("b-form-input", {
                          attrs: {
                            id: `input-${_vm.id}`,
                            name: `input-${_vm.id}`,
                            type: _vm.type,
                            disabled: "",
                            plaintext: "",
                            "aria-describedby": `input-${_vm.id}-live-feedback`,
                          },
                          model: {
                            value: _vm.inputVal,
                            callback: function ($$v) {
                              _vm.inputVal = $$v
                            },
                            expression: "inputVal",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }