var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    {
      attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
      scopedSlots: _vm._u([
        {
          key: "overlay",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("base-icon", {
                    attrs: { name: "loading", width: "35", height: "35" },
                  }),
                  _vm._v(" "),
                  _c("p", { attrs: { id: "cancel-label" } }, [
                    _vm._v("Operazione in corso..."),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "container mt-5 my-5" },
            [
              _c("h5", [_vm._v("Permessi POLCLIREL")]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "\n      PDF:\n      " +
                    _vm._s(_vm.canReport("POLCLIRELLO", "pdf")) +
                    " CSV:\n      " +
                    _vm._s(_vm.canReport("POLCLIREL", "csv")) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _c("h5", [
                _vm._v("use_fee: " + _vm._s(_vm.$store.state.auth.use_fee)),
              ]),
              _vm._v(" "),
              _c("h5", [_vm._v("lisacomunica")]),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "lisaweb" },
                  on: {
                    click: function ($event) {
                      return _vm.sms_show()
                    },
                  },
                },
                [_vm._v("SMS")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "lisaweb" },
                  on: {
                    click: function ($event) {
                      return _vm.sms_index()
                    },
                  },
                },
                [_vm._v("SMS ALL")]
              ),
              _vm._v(" "),
              _c("h5", [_vm._v("Genera CSV")]),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "lisaweb" },
                  on: {
                    click: function ($event) {
                      return _vm.delete403()
                    },
                  },
                },
                [_vm._v("Delete403")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "lisaweb" },
                  on: {
                    click: function ($event) {
                      return _vm.generateReport()
                    },
                  },
                },
                [_vm._v("Genera CSV")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.right",
                      modifiers: { hover: true, right: true },
                    },
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.html",
                      modifiers: { hover: true, html: true },
                    },
                  ],
                  staticClass: "notes-counter",
                  attrs: { title: _vm.notes_tooltip_content },
                },
                [_vm._v("\n      " + _vm._s(_vm.notes_counter) + "\n    ")]
              ),
              _vm._v(" "),
              _c("consolidation-calendar", {
                on: { input: _vm.printConsDate },
                model: {
                  value: _vm.consDate,
                  callback: function ($$v) {
                    _vm.consDate = $$v
                  },
                  expression: "consDate",
                },
              }),
              _vm._v(" "),
              _c(
                "b-card",
                {
                  staticClass: "mt-1",
                  attrs: { header: "Compagnie Coass", "header-tag": "header" },
                },
                [
                  _c(
                    "b-card-text",
                    [
                      _c("coass-details", {
                        ref: "coassRef",
                        attrs: {
                          details: _vm.coassdtls,
                          repository: "insurer_participant",
                          canDelete: "",
                        },
                        on: {
                          select: function ($event) {
                            return _vm.selectCreateCoassDetail(
                              _vm.form.coass.coass_details,
                              ...arguments
                            )
                          },
                          unselect: function ($event) {
                            return _vm.unselectCreateCoassDetail(
                              _vm.form.coass.coass_details,
                              ...arguments
                            )
                          },
                          delete: function ($event) {
                            return _vm.deleteCreateCoassDetail(
                              _vm.form.coass.coass_details,
                              ...arguments
                            )
                          },
                        },
                        model: {
                          value: _vm.form,
                          callback: function ($$v) {
                            _vm.form = $$v
                          },
                          expression: "form",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "mb-2",
                  attrs: { type: "button", variant: "lisaweb" },
                  on: {
                    click: function ($event) {
                      return _vm.$refs[_vm.tableRef].fetch()
                    },
                  },
                },
                [_vm._v("Carica")]
              ),
              _vm._v(" "),
              _c("table-test", {
                ref: _vm.tableRef,
                attrs: {
                  fields: _vm.fields,
                  repository: _vm.repository,
                  resource: _vm.resource,
                  filterName: _vm.filterName,
                  noActions: "",
                  includeRowSelector: "",
                },
                on: {
                  rowSelector: _vm.aggiorna,
                  "~cellChange": function ($event) {
                    return _vm.includi.apply(null, arguments)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "mb-2",
                  attrs: { type: "button", variant: "lisaweb" },
                  on: { click: _vm.aggiornaTutti },
                },
                [_vm._v("Aggiorna Tutti")]
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("p", [_vm._v("Anagrafica")]),
                  _vm._v(" "),
                  _c(
                    "b-input-group",
                    { staticClass: "mt-0", attrs: { size: "sm" } },
                    [
                      _c("base-input", {
                        model: {
                          value: _vm.inpt_label,
                          callback: function ($$v) {
                            _vm.inpt_label = $$v
                          },
                          expression: "inpt_label",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                size: "sm",
                                text: "Button",
                                variant: "lisaweb",
                              },
                              on: { click: _vm.openQuickSearch },
                            },
                            [_vm._v("Ricerca Veloce")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", [_vm._v("Label: " + _vm._s(_vm.inpt_label))]),
              _vm._v(" "),
              _c("p", [_vm._v("V-Model: " + _vm._s(_vm.form.quick_value))]),
              _vm._v(" "),
              _vm.insurance_policy_data
                ? _c("div", { staticClass: "insurance_policy_id" }, [
                    _c("span", { staticClass: "info" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            "Numero Polizza: " +
                              _vm.insurance_policy_data.number
                          ) +
                          "\n        "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            "Titolo polizza: " + _vm.insurance_policy_data.title
                          ) +
                          "\n        "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            "Codice Mandato: " +
                              _vm.insurance_policy_data.mandate_code
                          ) +
                          "\n        "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n        Anagrafica:\n        " +
                          _vm._s(
                            _vm.insurance_policy_data.registry.status_registry
                              .value === 0
                              ? _vm.insurance_policy_data.registry.attributables
                                  .NAME +
                                  " " +
                                  _vm.insurance_policy_data.registry
                                    .attributables.SURN
                              : _vm.insurance_policy_data.registry.attributables
                                  .CNAM
                          ) +
                          "\n      "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("quick-search-modal", {
                ref: "quickSearch",
                on: { set: _vm.setValue, input: _vm.handleModalInput },
                model: {
                  value: _vm.form.quick,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "quick", $$v)
                  },
                  expression: "form.quick",
                },
              }),
              _vm._v(" "),
              _c("quick-search-policy-modal", {
                ref: "quickSearchPolicy",
                on: { input: _vm.handlePolicyModalInput },
              }),
              _vm._v(" "),
              _c("base-input", { attrs: { name: "Quick", label: "Quick" } }),
              _vm._v(" "),
              _c("b-button", { on: { click: _vm.openQuickSearch } }, [
                _vm._v(" Quick Search "),
              ]),
              _vm._v(" "),
              _c("b-button", { on: { click: _vm.openQuickSearchPolicy } }, [
                _vm._v(" Quick Search Table"),
              ]),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { type: "button", variant: "lisaweb" },
                  on: { click: _vm.massDestroy },
                },
                [_vm._v("Test Tasks Mass Destroy")]
              ),
              _vm._v(" "),
              _c("h5", [_vm._v("Registry SHOW")]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("ValidationObserver", {
                ref: "observer1",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ invalid, handleSubmit }) {
                        return [
                          _c(
                            "form",
                            {
                              ref: "form1",
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return handleSubmit(_vm.onSubmit1)
                                },
                              },
                            },
                            [
                              _c("test-input", {
                                attrs: {
                                  name: "attribute_NINO_value",
                                  vid: "attribute_NINO_value",
                                  label:
                                    "attribute_NINO_value (backend validation)",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.apiValidate(
                                      "registry",
                                      "attribute_NINO_value",
                                      "observer1"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.registry.attribute_NINO_value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.registry,
                                      "attribute_NINO_value",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.registry.attribute_NINO_value",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  attrs: { type: "submit", disabled: invalid },
                                },
                                [_vm._v("Submit")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.testValidate("test1")
                                    },
                                  },
                                },
                                [_vm._v("Backend")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.reset("form1", "observer1")
                                    },
                                  },
                                },
                                [_vm._v("\n          Reset\n        ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2528706208
                ),
              }),
              _vm._v(" "),
              _c("h5", [_vm._v("REPORT")]),
              _vm._v(" "),
              _c("validation-observer", {
                ref: "observer",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ handleSubmit }) {
                        return [
                          _c(
                            "b-form",
                            {
                              ref: "form",
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return handleSubmit(_vm.onSubmit2)
                                },
                              },
                            },
                            [
                              _c("base-select", {
                                attrs: {
                                  name: "Nazione",
                                  vid: "country",
                                  label: "Nazione",
                                  options: _vm.optCountries,
                                  rules: { required: true },
                                },
                                on: { select: _vm.onSelectCountry },
                                model: {
                                  value: _vm.country,
                                  callback: function ($$v) {
                                    _vm.country = $$v
                                  },
                                  expression: "country",
                                },
                              }),
                              _vm._v(" "),
                              _c("base-select", {
                                attrs: {
                                  name: "Provincia",
                                  vid: "province",
                                  label: "Provincia",
                                  options: _vm.optProvinces,
                                  rules: { required: true },
                                },
                                model: {
                                  value: _vm.province,
                                  callback: function ($$v) {
                                    _vm.province = $$v
                                  },
                                  expression: "province",
                                },
                              }),
                              _vm._v(" "),
                              _c("base-currency", {
                                attrs: {
                                  vid: "Amount",
                                  name: "Amount",
                                  label: "Euro Amount",
                                  options: {
                                    locale: "it-IT",
                                    currency: "EUR",
                                  },
                                  rules: _vm.getRules("Amount"),
                                },
                                model: {
                                  value: _vm.form.amount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "amount", $$v)
                                  },
                                  expression: "form.amount",
                                },
                              }),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "Price (in parent component): " +
                                    _vm._s(_vm.form.amount)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("base-input", {
                                attrs: {
                                  vid: "attributables_NAME",
                                  name: "attributables_NAME",
                                  label: "Nome",
                                  rules: {
                                    required_with: "@attributables_SURN",
                                  },
                                  textCase: "upper",
                                  readonly: true,
                                },
                                model: {
                                  value: _vm.form.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name",
                                },
                              }),
                              _vm._v(" "),
                              _c("base-input", {
                                attrs: {
                                  vid: "attributables_SURN",
                                  name: "attributables_SURN",
                                  label: "Cognome",
                                  textcase: "upper",
                                },
                                model: {
                                  value: _vm.form.surname,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "surname", $$v)
                                  },
                                  expression: "form.surname",
                                },
                              }),
                              _vm._v(" "),
                              _c("base-input", {
                                attrs: {
                                  vid: "CELL",
                                  name: "CELL",
                                  label: "Cellulare",
                                  type: "tel",
                                },
                                model: {
                                  value: _vm.form.cell,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "cell", $$v)
                                  },
                                  expression: "form.cell",
                                },
                              }),
                              _vm._v(" "),
                              _c("base-currency", {
                                attrs: {
                                  vid: "decimal",
                                  name: "Decimal",
                                  label: "Decimal",
                                  options: {
                                    locale: "it-IT",
                                    currency: "EUR",
                                    currencyDisplay: "hidden",
                                    precision: 2,
                                  },
                                  rules: _vm.getRules("Decimal"),
                                },
                                model: {
                                  value: _vm.form.decimal,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "decimal", $$v)
                                  },
                                  expression: "form.decimal",
                                },
                              }),
                              _vm._v(" "),
                              _c("base-select", {
                                attrs: {
                                  name: "food",
                                  vid: "food",
                                  label: "Cibo",
                                  options: _vm.foods,
                                  rules: { required: true },
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.form.food,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "food", $$v)
                                  },
                                  expression: "form.food",
                                },
                              }),
                              _vm._v(" "),
                              _c("base-select", {
                                attrs: {
                                  name: "beers",
                                  vid: "beers",
                                  label: "Birre",
                                  options: _vm.beers,
                                  multiple: true,
                                  taggable: false,
                                  closeOnSelect: false,
                                  rules: {
                                    required_if: {
                                      target: "food",
                                      values: ["apple"],
                                    },
                                  },
                                },
                                on: {
                                  input: _vm.onInputBeers,
                                  select: _vm.onSelectBeers,
                                  remove: _vm.onRemoveBeers,
                                },
                                model: {
                                  value: _vm.form.beers,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "beers", $$v)
                                  },
                                  expression: "form.beers",
                                },
                              }),
                              _vm._v(" "),
                              _c("base-select", {
                                attrs: {
                                  name: "Tags",
                                  vid: "tags",
                                  label: "Tagghi",
                                  options: _vm.tags,
                                  rules: { required: true },
                                  taggable: true,
                                  multiple: true,
                                  closeOnSelect: false,
                                },
                                model: {
                                  value: _vm.form.tag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "tag", $$v)
                                  },
                                  expression: "form.tag",
                                },
                              }),
                              _vm._v(" "),
                              _c("base-datepicker", {
                                attrs: {
                                  vid: "Date",
                                  name: "Una data",
                                  label: "Una data",
                                },
                                model: {
                                  value: _vm.form.aDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "aDate", $$v)
                                  },
                                  expression: "form.aDate",
                                },
                              }),
                              _vm._v(
                                "\n        DATA: " +
                                  _vm._s(_vm.form.aDate) +
                                  "\n        "
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.$set(
                                        _vm.form,
                                        "aDate",
                                        "1900-01-31"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Proceed")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.form.aDate = null
                                    },
                                  },
                                },
                                [_vm._v("Clear Only")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.form.aDate = null
                                      _vm.$set(_vm.form, "aDate", _vm.savedDate)
                                    },
                                  },
                                },
                                [_vm._v("\n          Clear and Set\n        ")]
                              ),
                              _vm._v(" "),
                              _c("base-datepicker", {
                                attrs: {
                                  vid: "Another",
                                  name: "Altra data",
                                  label: "Altra data",
                                },
                                model: {
                                  value: _vm.form.anotherDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "anotherDate", $$v)
                                  },
                                  expression: "form.anotherDate",
                                },
                              }),
                              _vm._v(" "),
                              _c("test-datepicker", {
                                attrs: {
                                  vid: "born_test_date",
                                  name: "Test Data",
                                  label: "Nato il (test datepicker)",
                                  rules: { required: true },
                                },
                                model: {
                                  value: _vm.form.aTestDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "aTestDate", $$v)
                                  },
                                  expression: "form.aTestDate",
                                },
                              }),
                              _vm._v(" "),
                              _c("base-timepicker", {
                                attrs: {
                                  vid: "born_time",
                                  name: "Orario",
                                  label: "Nato alle (test timepicker)",
                                  rules: { required: true },
                                },
                                model: {
                                  value: _vm.form.aTestTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "aTestTime", $$v)
                                  },
                                  expression: "form.aTestTime",
                                },
                              }),
                              _vm._v(" "),
                              _c("base-month-picker", {
                                attrs: {
                                  vid: "month",
                                  name: "Mese",
                                  label: "Scegli il periodo",
                                  clearable: true,
                                },
                                model: {
                                  value: _vm.form.period,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "period", $$v)
                                  },
                                  expression: "form.period",
                                },
                              }),
                              _vm._v(" "),
                              _c("p", [_vm._v(_vm._s(_vm.form.month))]),
                              _vm._v(" "),
                              _c("base-checkbox", {
                                attrs: {
                                  name: "Agree",
                                  label: "Agree the terms",
                                  rules: { required: true },
                                  chk_val: "1",
                                  unchk_val: "0",
                                  disabled: false,
                                },
                                model: {
                                  value: _vm.form.agree,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "agree", $$v)
                                  },
                                  expression: "form.agree",
                                },
                              }),
                              _vm._v(" "),
                              _c("advanced-checkbox", {
                                attrs: {
                                  name: "Social",
                                  label: "I miei social",
                                  options: _vm.multiple,
                                  rules: { required: true },
                                },
                                model: {
                                  value: _vm.form.multiple,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "multiple", $$v)
                                  },
                                  expression: "form.multiple",
                                },
                              }),
                              _vm._v(" "),
                              _c("advanced-checkbox", {
                                attrs: {
                                  name: "Bands",
                                  label: "Big 4",
                                  options: _vm.bands,
                                  rules: { required: true },
                                },
                                model: {
                                  value: _vm.form.bands,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "bands", $$v)
                                  },
                                  expression: "form.bands",
                                },
                              }),
                              _vm._v(" "),
                              _c("base-radio", {
                                attrs: {
                                  name: "Radios",
                                  vid: "radios",
                                  label: "Choose your radio",
                                  options: _vm.radios,
                                  rules: { required: true },
                                },
                                model: {
                                  value: _vm.form.radio,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "radio", $$v)
                                  },
                                  expression: "form.radio",
                                },
                              }),
                              _vm._v(" "),
                              _c("base-radio", {
                                attrs: {
                                  name: "Radios2",
                                  vid: "radios2",
                                  label: "Choose your radio 2",
                                  options: _vm.radios2,
                                  rules: { required: true },
                                },
                                model: {
                                  value: _vm.form.radio2,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "radio2", $$v)
                                  },
                                  expression: "form.radio2",
                                },
                              }),
                              _vm._v(" "),
                              _c("base-textarea", {
                                attrs: {
                                  name: "textArea",
                                  vid: "textArea",
                                  label: "Una Text Area",
                                  rows: "4",
                                  maxrows: "4",
                                },
                                model: {
                                  value: _vm.form.textarea,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "textarea", $$v)
                                  },
                                  expression: "form.textarea",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  attrs: { type: "submit", variant: "lisaweb" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onSubmit2()
                                    },
                                  },
                                },
                                [_vm._v("Submit")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  staticClass: "ml-2",
                                  on: {
                                    click: function ($event) {
                                      return _vm.resetForm()
                                    },
                                  },
                                },
                                [_vm._v("Reset")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2225856928
                ),
              }),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("base-dynamic-input", {
                attrs: {
                  name: "baseDynamicInputManual",
                  isAssistence: _vm.isAssistence ? true : false,
                  type: "text",
                  controlType: "text",
                  label: "Base Dynamic Input (manual control)",
                  "aria-placeholder": "",
                  rules: { required: true },
                  textcase: "upper",
                },
                model: {
                  value: _vm.baseDynamicInputManual,
                  callback: function ($$v) {
                    _vm.baseDynamicInputManual = $$v
                  },
                  expression: "baseDynamicInputManual",
                },
              }),
              _vm._v(" "),
              _c("advanced-dynamic-input", {
                attrs: {
                  name: "advancedDynamicInputManual",
                  isAssistence: _vm.isAssistence ? true : false,
                  content: {
                    name: _vm.relationData.name,
                    addr: _vm.relationData.addr,
                    cf: _vm.relationData.cf,
                    ispl: _vm.relationData.ispl,
                    ispl_date: _vm.relationData.ispl_date,
                  },
                  type: "text",
                  controlType: "text",
                  label: "Advanced Dynamic Input (manual control)",
                  "aria-placeholder": "",
                  rules: { required: true },
                },
                model: {
                  value: _vm.relationData.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.relationData, "id", $$v)
                  },
                  expression: "relationData.id",
                },
              }),
              _vm._v(" "),
              _c("base-radio", {
                attrs: {
                  name: "Poteri",
                  label: "Choose your powers!",
                  options: [
                    { value: 0, text: "Agenzia" },
                    { value: 1, text: "Assistenza" },
                  ],
                },
                model: {
                  value: _vm.isAssistence,
                  callback: function ($$v) {
                    _vm.isAssistence = $$v
                  },
                  expression: "isAssistence",
                },
              }),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("base-dynamic-input", {
                attrs: {
                  name: "baseDynamicInputAcl",
                  isAssistence: _vm.roles.includes("admin-assistenza"),
                  type: "text",
                  controlType: "text",
                  label: "Base Dynamic Input (acl control)",
                  "aria-placeholder": "",
                  rules: { required: true },
                },
                model: {
                  value: _vm.baseDynamicInputAcl,
                  callback: function ($$v) {
                    _vm.baseDynamicInputAcl = $$v
                  },
                  expression: "baseDynamicInputAcl",
                },
              }),
              _vm._v(" "),
              _c("advanced-dynamic-input", {
                attrs: {
                  name: "advancedDynamicInputAcl",
                  isAssistence: _vm.roles.includes("admin-assistenza"),
                  type: "text",
                  controlType: "text",
                  label: "Advanced Dynamic Input (acl control)",
                  "aria-placeholder": "",
                  rules: { required: true },
                },
                model: {
                  value: _vm.advancedDynamicInputAcl,
                  callback: function ($$v) {
                    _vm.advancedDynamicInputAcl = $$v
                  },
                  expression: "advancedDynamicInputAcl",
                },
              }),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "mb-2",
                  attrs: { type: "button", variant: "lisaweb" },
                  on: {
                    click: function ($event) {
                      return _vm.errorTest()
                    },
                  },
                },
                [_vm._v("Error test a")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "mb-2",
                  attrs: { type: "button", variant: "lisaweb" },
                  on: {
                    click: function ($event) {
                      return _vm.openModal(1, 0, $event.target)
                    },
                  },
                },
                [_vm._v("Apri General")]
              ),
              _vm._v(" "),
              _c(
                "b-modal",
                {
                  attrs: {
                    id: _vm.infomodalName,
                    title: _vm.modalTitle,
                    size: "xl",
                    "ok-only": "",
                    "ok-variant": "lisaweb",
                    "header-bg-variant": "lisaweb",
                    "no-close-on-backdrop": "",
                  },
                },
                [
                  _c(_vm.selected, {
                    tag: "component",
                    attrs: { resourceId: _vm.modalResourceId },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }