var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _c("b-table", {
        attrs: {
          busy: _vm.isLoading,
          bordered: "",
          "head-variant": "light",
          "no-local-sorting": true,
          items: _vm.items,
          fields: _vm.getAllFields,
          "current-page": _vm.currentPage,
          "per-page": "0",
          "sort-by": _vm.sortBy,
          "sort-desc": _vm.sortDesc,
          "sort-direction": _vm.sortDirection,
          stacked: "md",
          "show-empty": "",
          "empty-text": "Nada",
          small: "",
          "tbody-tr-class": _vm.rowClass,
        },
        on: {
          "update:sortBy": function ($event) {
            _vm.sortBy = $event
          },
          "update:sort-by": function ($event) {
            _vm.sortBy = $event
          },
          "update:sortDesc": function ($event) {
            _vm.sortDesc = $event
          },
          "update:sort-desc": function ($event) {
            _vm.sortDesc = $event
          },
          "sort-changed": _vm.sortingChanged,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "table-busy",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center my-2" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", [_vm._v("Operazione in corso...")]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "cell(rowSelector)",
              fn: function (row) {
                return [
                  _c(
                    "div",
                    { staticClass: "action-buttons" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-1 mr-1",
                          attrs: {
                            size: "sm",
                            variant: "lisaweb",
                            title: "Seleziona",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onChosen(row.item)
                            },
                          },
                        },
                        [_vm._v("Aggiorna\n           ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "cell(insurer_id)",
              fn: function ({ item }) {
                return [
                  _c("base-currency", {
                    attrs: {
                      name: "insurer_id",
                      options: { currency: "EUR", locale: "it-IT" },
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onCellChange(item.id)
                      },
                    },
                    model: {
                      value: item.insurer_id,
                      callback: function ($$v) {
                        _vm.$set(item, "insurer_id", $$v)
                      },
                      expression: "item.insurer_id",
                    },
                  }),
                ]
              },
            },
            {
              key: "bottom-row",
              fn: function (data) {
                return _vm._l(data.fields, function (field, i) {
                  return _c("td", { key: i }, [
                    _c("b", [
                      _vm._v(
                        _vm._s(i === 0 ? "Totale" : "") +
                          "\n           " +
                          _vm._s(
                            i !== 0 && field.key === "insurer_id"
                              ? _vm.toLocaleCurrency(
                                  _vm.totalizeFields(_vm.items, field.key)
                                )
                              : null
                          ) +
                          "\n           " +
                          _vm._s(
                            i !== 0 && field.key === "attributables.CUST1"
                              ? _vm.toLocaleCurrency(
                                  _vm.totalizeFields(_vm.items, field.key)
                                )
                              : null
                          ) +
                          "\n           " +
                          _vm._s(
                            i !== 0 && field.key === "attributables.CUST2"
                              ? _vm.totalQuantity(_vm.items, field.key)
                              : null
                          ) +
                          "\n         "
                      ),
                    ]),
                  ])
                })
              },
            },
            _vm.hasChecks && !_vm.isCheckExclusive
              ? {
                  key: "head(check)",
                  fn: function () {
                    return [
                      _c("base-checkbox", {
                        staticClass: "check",
                        attrs: { name: "all" },
                        on: { change: _vm.onSelectAllRows },
                        model: {
                          value: _vm.checkAll,
                          callback: function ($$v) {
                            _vm.checkAll = $$v
                          },
                          expression: "checkAll",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
            _vm.hasChecks
              ? {
                  key: "cell(check)",
                  fn: function (row) {
                    return [
                      _c("base-checkbox", {
                        staticClass: "check",
                        attrs: { name: "check" },
                        on: {
                          input: function ($event) {
                            return _vm.onSelectRow($event, row.index, row.item)
                          },
                        },
                        model: {
                          value: row.item.check,
                          callback: function ($$v) {
                            _vm.$set(row.item, "check", $$v)
                          },
                          expression: "row.item.check",
                        },
                      }),
                    ]
                  },
                }
              : null,
            {
              key: "cell(selezionato)",
              fn: function ({ item }) {
                return [
                  _c("base-checkbox", {
                    staticClass: "check",
                    attrs: { name: "selected", chk_val: "1", unchk_val: "0" },
                    on: {
                      input: function ($event) {
                        return _vm.onSelezionato($event, item)
                      },
                    },
                    model: {
                      value: item.selected,
                      callback: function ($$v) {
                        _vm.$set(item, "selected", $$v)
                      },
                      expression: "item.selected",
                    },
                  }),
                ]
              },
            },
            {
              key: "cell(actions)",
              fn: function (row) {
                return [
                  _c(
                    "div",
                    { staticClass: "action-buttons" },
                    [
                      _vm.actions.includes("infomodal")
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mt-1 mr-1",
                              attrs: {
                                size: "sm",
                                variant: "lisaweb",
                                title: "Modale Dettagli",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openModal(
                                    row.item,
                                    row.index,
                                    $event.target
                                  )
                                },
                              },
                            },
                            [_c("b-icon", { attrs: { icon: "eye" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.actions.includes("details")
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mt-1 mr-1",
                              attrs: {
                                size: "sm",
                                variant: "lisaweb",
                                title: row.detailsShowing
                                  ? "Chiudi dettagli"
                                  : "Apri dettagli",
                              },
                              on: { click: row.toggleDetails },
                            },
                            [
                              row.detailsShowing
                                ? _c("b-icon", {
                                    attrs: { icon: "chevron-up" },
                                  })
                                : _c("b-icon", {
                                    attrs: { icon: "chevron-down" },
                                  }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.actions.includes("edit")
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mt-1 mr-1",
                              attrs: {
                                size: "sm",
                                variant: "lisaweb",
                                title: "Modifica",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onEdit(row.item.id)
                                },
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: { icon: "pencil-square" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.actions.includes("destroy")
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mt-1 mr-1",
                              attrs: {
                                size: "sm",
                                variant: "lisaweb",
                                title: "Elimina",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onDestroy(row.item.id)
                                },
                              },
                            },
                            [_c("b-icon", { attrs: { icon: "trash" } })],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "row-details",
              fn: function (row) {
                return [
                  _c(
                    "b-card",
                    _vm._l(row.item, function (value, key) {
                      return _c(
                        "b-row",
                        { key: key, staticClass: "mb-2" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "text-sm-right",
                              attrs: { sm: "3" },
                            },
                            [
                              _c("b", [
                                _vm._v(_vm._s(_vm._f("capitalize")(key)) + ":"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("b-col", [_vm._v(_vm._s(value))]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _vm._v(" "),
      _c("b-row", { attrs: { "align-h": "end" } }, [
        _c("div", { staticClass: "show-text" }, [_c("p", [_vm._v("Mostra")])]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "group" },
          [
            _c(
              "b-form-group",
              {
                attrs: {
                  "label-for": "per-page-select",
                  "label-cols-sm": "3",
                  "label-cols-md": "",
                  "label-cols-lg": "3",
                  "label-size": "sm",
                  md: "auto",
                },
              },
              [
                _c("b-form-select", {
                  staticStyle: { width: "68px" },
                  attrs: {
                    id: "per-page-select",
                    options: _vm.pageOptions,
                    size: "sm",
                    disabled: !_vm.items.length,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.onPageChange(1)
                    },
                  },
                  model: {
                    value: _vm.perPage,
                    callback: function ($$v) {
                      _vm.perPage = $$v
                    },
                    expression: "perPage",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "page" }, [
          _c("p", [_vm._v("di " + _vm._s(_vm.totalItems) + " per pagina")]),
        ]),
        _vm._v(" "),
        _vm.totalItems
          ? _c(
              "div",
              { staticClass: "pagination" },
              [
                _c("b-pagination", {
                  attrs: {
                    limit: "3",
                    "total-rows": _vm.totalItems,
                    "per-page": _vm.perPage,
                    size: "sm",
                  },
                  on: { change: _vm.onPageChange },
                  model: {
                    value: _vm.currentPage,
                    callback: function ($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: _vm.infoModal.id,
            title: _vm.infoModal.title,
            size: "xl",
            "ok-only": "",
            "ok-variant": "lisaweb",
            "header-bg-variant": "lisaweb",
            scrollable: "",
          },
          on: { hide: _vm.resetInfoModal },
        },
        [_c("pre", [_vm._v(_vm._s(_vm.infoModal.content))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }