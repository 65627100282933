var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.anag_status_registry === _vm.section_status_registry ||
    _vm.section_status_registry === "Standard"
    ? _c(
        "div",
        [
          _c(
            "b-card",
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("p", { staticClass: "mb-0 d-inline-block align-middle" }, [
                    _vm._v("\n        " + _vm._s(_vm.header) + "\n      "),
                  ]),
                  _vm._v(" "),
                  _vm.buttonhtml
                    ? _c(
                        "b-btn",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            size: "sm",
                            variant: "lisaweb",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.edit()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " + _vm._s(_vm.buttonhtml) + "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.fields, function (field, index) {
                return _c("b-row", { key: index }, [
                  _c("div", { staticClass: "col-md-4 border-top" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.labels[field.label]
                            ? _vm.labels[field.label]
                            : field.label
                        ) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.editable
                    ? _c(
                        "div",
                        { staticClass: "col-md-8 border-top border-left" },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: `input-${index}`,
                              name: `input-${index}`,
                              type: "text",
                            },
                            model: {
                              value: _vm.form[field.value],
                              callback: function ($$v) {
                                _vm.$set(_vm.form, field.value, $$v)
                              },
                              expression: "form[field.value]",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "col-md-8 border-top border-left" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.texts(field.value)) +
                              "\n      "
                          ),
                        ]
                      ),
                ])
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.visible,
                      expression: "visible",
                    },
                  ],
                  staticClass: "py-2 mb-4",
                },
                [
                  _c(
                    "b-btn",
                    {
                      attrs: { variant: "lisaweb", size: "sm" },
                      on: {
                        click: function ($event) {
                          return _vm.onSave()
                        },
                      },
                    },
                    [_vm._v("\n        Salva\n      ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }